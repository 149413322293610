@import "../assets/fonts/Gilroy/stylesheet.css";
@import "../assets/fonts/Montserrat/stylesheet.css";
@import "../assets/fonts/Oswald/stylesheet.css";
@import "../assets/fonts/GillSans/stylesheet.css";
@import "../assets/fonts/APEXMK3//fonts.css";
@import "../assets/fonts/Poppins/poppins.css";
@import "../assets/fonts/SFPRO/SFPRO.css";
@import "../assets/fonts/Seahawks/Seahawks.css";
@import "../assets/fonts/Copperplate/Copperplate.css";
@import "../assets/fonts/Mariners/Mariners.css";
@import "../assets/fonts/SITickets/Industry.css";

@import "~antd/dist/antd.css";

@import "./calendar";

.ant-spin-dot-item {
  background-color: white;
}

.ant-modal {
  top: 85px;
}

.ant-select .ant-select-selection-item {
  font-size: 20px !important;
}

.ant-select-dropdown {
  min-width: 200px !important; /* Adjust width */
  padding: 4px !important; /* Padding inside dropdown */
  border-radius: 8px !important; /* Rounded edges */
}

.ant-select-item-option {
  height: 40px !important;
  line-height: 40px !important;
  font-size: 16px !important;
  padding: 5px 12px !important;
}

/* Make the selected option rounded */
.ant-select-item-option-selected {
  background-color: #f0f0f0 !important;
  border-radius: 6px !important;
}

.ant-select-dropdown {
  background-color: white !important;
  border-radius: 12px !important; /* Fully rounded dropdown */
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1) !important;
}


.ant-select-item-option:hover {
  background-color: #f5f5f5 !important;
  border-radius: 6px !important; /* Rounded edges */
}

.ant-select .ant-select-selection-placeholder {
  font-size: 20px !important;
}

.ant-modal-close-x {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #FFFFFF;
}

.ant-switch-moment-header {
  .ant-switch-handle::before {
    background-color: white;
  }
}

.ant-switch-moment-header-off {
  .ant-switch-handle::before {
    background-color: gray;
  }
}

.slick-slider {
  width: 100%;
  flex-grow: 1;
}

.slick-list {
  height: 100%;
}

.slick-track {
  display: flex;
  height: 100%;
}
.slick-track .slick-slide {
  display: flex;
  height: 100%;

  & > div {
    height: 100%;
  }
}

.ant-modal-header {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.ant-modal-content {
  border-radius: 7px;
}

.momentoModal {
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  max-width: 100vw;

  & > .ant-modal-content {
    height: 100%;
    background: grey;
    border-radius: 7px;

    & > .ant-modal-body {
      height: 100%;
      padding: 0px;
    }

    & > .ant-modal-close {
      color: white;
      background: #1a1919;
      border-bottom-left-radius: 10px;
    }
  }
}

.toolbar-with-modals {
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    background: rgb(212, 205, 205);
  }
  .ant-modal-close {
    color: black;
    background: #003263;
    border-bottom-left-radius: 10px;
  }
}
